



















































import {Vue, Component, Prop} from 'vue-property-decorator'
import {NftCollection} from '@/model/resource/NftCollection'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'

@Component({})
export default class TokenPriceRender extends MixinScreenSize {
  @Prop({type: String, default: null}) price!: string
  @Prop({type: String, default: null}) poolLiquidityUSD!: string

  get displayWarning() {
    return parseFloat(this.poolLiquidityUSD) < 200000
  }

  get popoverText() {
    return $.translate('components.TokenPriceRender.warning')
  }
}
