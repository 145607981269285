import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseExpose,
} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import moment from 'moment/moment'
import {$} from '@/facade'

@HttpExclude()
export class TokensTableItemsCollection extends ExpansibleCollection<
  TokensTableItem
> {
  resource?: ITokensTableItemsCollectionResourceHolder

  @RequestExpose() minOrder: number | null = null
  @RequestExpose() isDeprecated: boolean | null = null
  @RequestExpose() slugDapp: string | null = null
  @RequestExpose() networkIdentifier: string | null = null
  @RequestExpose() idBlockchainVersionFk: Array<number> = []
  @RequestExpose() active: boolean = true
  @RequestExpose() minimumLiquidityPool: number = 10000
  @RequestExpose() withoutTotal: boolean = true

  @ResponseExpose() lastTimeUpdated: string | null = null

  constructor() {
    super(TokensTableItem)
  }

  get lastTimeUpdatedFormatted() {
    const date = moment.utc(this.lastTimeUpdated)

    return date.format($.t('dateFormat.datetimeAlmostFull') as string)
  }

  async queryAsPage() {
    return this.listTokensTableItems()
  }

  async listTokensTableItems() {
    this.expandedItems = this.items

    return await Request.get(`/client/tokens-table-item`, {
      params: this.params,
    })
      .name('loadTokensTableItems')
      .as(this)
      .getResponse()
  }
}

export interface ITokensTableItemsCollectionResourceHolder {}
