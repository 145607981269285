import axios from 'axios'
import {EnvHelper} from '@/helpers/EnvHelper'

export type NetworkStatistics = {
  totalTransactions: number
  totalAddresses: number
  totalContracts: number
  currentWeekTransactions: number
  currentWeekAddresses: number
  currentWeekContracts: number
}

type Token = {
  hash: string
  symbol: string
  decimals: number
}

export type BalanceResponse = {
  token: Token
  amount: string
}

export class ShrikeHelper {
  static async getNetworkStatistics(): Promise<NetworkStatistics> {
    const url = `${EnvHelper.SHRIKE_URL}v1/stat/network-statistics`

    const {data} = await axios.get(url)

    return {
      totalTransactions: data.total_transactions,
      totalAddresses: data.total_addresses,
      totalContracts: data.total_contracts,
      currentWeekTransactions: data.current_week_transactions,
      currentWeekAddresses: data.current_week_addresses,
      currentWeekContracts: data.current_week_contracts,
    }
  }

  static async getBalance(address: string): Promise<BalanceResponse[]> {
    const url = EnvHelper.VUE_APP_RPC_URL

    const {data} = await axios.post(url, {
      jsonrpc: '2.0',
      method: 'getnep17balances',
      params: [address],
      id: 1,
    })

    return data.result.balance.map((item: any) => {
      return {
        token: {
          hash: item.assethash,
          symbol: item.symbol,
          decimals: item.decimals,
        },
        amount: Number(item.amount / 10 ** item.decimals),
      }
    })
  }
}
