


















































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {$} from '@/facade'

type Theme = 'cyan' | 'black'

@Component
export default class TokensSwapButton extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, required: true}) isSwappable!: boolean
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem
  @Prop({type: String, required: false, default: 'black'}) theme!: Theme
  @Prop({type: Boolean, required: false, default: false})
  isMyWalletPage!: boolean
  @Prop({
    type: String,
    required: false,
    default: $.translate('components.tokensSwapButton.tooltipText'),
  })
  popoverText!: string

  async connect() {
    await this.$walletAdapter.executeWithConnectedN3Wallet(() => {
      if (this.isMyWalletPage) this.$modal.open('MyWalletSwapModal', this.token)
      else this.$modal.open('swapModal', this.token)
    })
  }
}
