














































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'
import {PropType} from 'vue'
import SwapInput from '@/components/swapToken/SwapInput.vue'
import SwapTokenAccountBalance from '@/components/swapToken/SwapTokenAccountBalance.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {BalanceResponse} from '@/helpers/ShrikeHelper'

@Component({components: {SwapTokenAccountBalance, SwapInput, VueMultiselect}})
export default class SwapTokenToUseInputAndSelect extends Vue {
  @Prop({type: String, required: true}) amount!: string
  @Prop({type: String, required: true}) amountInDollar!: string
  @Prop({type: Array, required: true}) accountBalance!: BalanceResponse[]
  @Prop({type: Array, required: true}) options!: TokensTableItem[]
  @Prop({type: Object as PropType<TokensTableItem | null>})
  token!: TokensTableItem | null
  @Prop({type: Boolean, required: false, default: true})
  showDollarAmount!: boolean

  tokenAccountBalance: number = 0

  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  selectIsValid!: boolean | null
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  inputIsValid!: boolean | null

  @Prop({type: Boolean, required: true}) isTokenToReceiveIndivisible!: boolean
  @Prop({type: Boolean, required: true}) isTokenToUseIndivisible!: boolean

  isMaxAmount = false
  maxAmount: string | null = null

  handleSelect(val: TokensTableItem) {
    this.$emit('select', val)
  }

  async handleMaxClick() {
    this.$emit('maxClick')
    this.isMaxAmount = true
    await this.$utils.sleep(1000)
    this.maxAmount = this.amount
  }

  handleInput(val: string) {
    this.$emit('input', val)
    this.isMaxAmount = this.maxAmount === val && val !== ''
  }

  focusOnInput() {
    const input = this.$el.querySelector(
      '.swap-input-select__input'
    ) as HTMLInputElement
    input.focus()
  }

  @Watch('amount')
  async handleMaxAmount() {
    if (this.maxAmount) {
      this.isMaxAmount = this.maxAmount === this.amount && this.amount !== ''
    }
  }

  @Watch('token')
  setTokenAccountBalance() {
    const balance = this.accountBalance.find(
      balance => balance.token.hash === this.token?.marketInformation?.hash
    )

    this.tokenAccountBalance = Number(balance?.amount ?? 0)
  }
}
