





























import _ from 'lodash'
import ECharts from 'vue-echarts/components/ECharts.vue'
import moment from 'moment'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {ECharts},
})
export default class TokensPriceChart extends Mixins(MixinScreenSize) {
  @Prop({type: Array, required: true}) prices!: String[]
  @Prop({type: Number, required: true}) priceVariationLastWeek!: number

  get options() {
    const xAxisData = Array.from({length: 7}).map((_item, index) => {
      const nextDate = moment().subtract(7 - index - 1, 'day')
      return _.cloneDeep(nextDate)
    })

    const seriesData = xAxisData.map((item, index) => ({
      value: Number(this.prices[index]),
      itemStyle: {
        color: 'transparent',
      },
      symbolSize: 0,
    }))

    return {
      xAxis: {
        type: 'category',
        data: xAxisData.map(item => item.date()),
        show: false,
      },
      yAxis: {
        type: 'value',
        show: false,
        min: (value: any) => {
          return value.min
        },
        max: (value: any) => {
          return value.max
        },
      },
      series: {
        data: seriesData,
        type: 'line',
        lineStyle: {
          width: 3,
        },
      },
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: this.getColorStops,
        global: false, // false by default
      },
    }
  }

  get getColorStops() {
    if (this.priceVariationLastWeek >= 0) {
      return [
        {
          offset: 0,
          color: '#77fbaf', // color at 0% position
        },
        {
          offset: 1,
          color: '#134d89', // color at 100% position
        },
      ]
    }

    return [
      {
        offset: 0,
        color: '#fb77d5', // color at 0% position
      },
      {
        offset: 1,
        color: '#841706', // color at 100% position
      },
    ]
  }
}
