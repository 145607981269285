



















































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class Tooltip extends MixinScreenSize {
  @Prop({type: String, required: false}) text!: string | undefined
  @Prop({type: String, required: true}) label!: string
  @Prop({type: String, required: false}) btnClass!: string | undefined
  @Prop({type: Boolean, required: false, default: true}) showAsterisk!: boolean
  @Prop({type: String, required: false, default: 'left-start'})
  placement!: boolean

  mobileIsOpen = false

  handleClick() {
    this.mobileIsOpen = !this.mobileIsOpen
    this.$emit('onMobileOpen', this.mobileIsOpen)
  }
}
