












import {Vue, Component, Prop} from 'vue-property-decorator'
import {NftCollection} from '@/model/resource/NftCollection'

@Component({})
export default class TokenImageRender extends Vue {
  @Prop({type: String, default: null}) imageUrl!: String
}
